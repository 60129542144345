import './App.css';
import AppWrapper from './AppWrapper/AppWrapper';

function App() {
  return (
    <div className="App">
      <AppWrapper></AppWrapper>
    </div>
  );
}

export default App;
