import {useEffect, useRef, useState} from "react";
import { motion } from "motion/react";
import './DropDownButton.css';

function DropDownButton({children}) {

    const [open, setOpen] = useState(false);
    const dropdownRef = useRef(null);

    const toggleOpen = () => { setOpen(!open); };
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => document.removeEventListener("click", handleClickOutside);
    });

    return (
        <div className="dropDownButton" ref={dropdownRef}>
            <button className="drop-down-button" onClick={toggleOpen}>...</button>
            { open &&
                <motion.ul className="dropDownButton-dropDown" initial={{ y: -50, opacity: 0 }} animate={{ y: 0, opacity: 1 }}>
                    {children}
                </motion.ul>
            }
        </div>)
}

export default DropDownButton;