import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useQuery} from '@tanstack/react-query';
import {useState} from 'react';
import './SelectedRouteWidget.css';
import DropDownButton from "../../Elements/DropDownButton/DropDownButton";
import DropDownMenuButton from "../../Elements/DropDownButton/DropDownMenuButton/DropdownMenuButton";

const backendHost = window.APP_CONFIG.apiUrl;

function SelectedRouteWidget({route, open, onClose, onDelete, onArchive}) {
    const {isPending, data: logs, refetch} = useQuery({
        queryKey: ['logData', route],
        queryFn: () =>
            fetch(`${backendHost}/api/halls/0/routes/${route.id}/logs`).then((res) =>
                res.json(),
            ),
    });

    const [newPerson, setNewPerson] = useState(null);
    const [newDone, setNewDone] = useState(true);

    const onCreateLog = () => {
        const newLog = {
            person: newPerson,
            done: newDone,
        };
        fetch(`${backendHost}/api/halls/0/routes/${route.id}/logs`, {
            method: 'POST', // Methode fest
            body: JSON.stringify(newLog),
            headers: {
                "Content-Type": "application/json",
            }
        }).then(() => refetch());
    };


    if (!route || isPending) return (<div className='route-detail'></div>);
    return (
        <div className={`route-detail ${open ? 'open' : ''}`}>

            <div className="dropdown-container">
                <DropDownButton>
                    <DropDownMenuButton text={"Löschen"} iconName="trash" onClick={() => onDelete(route.id)} />
                    <DropDownMenuButton text={"Archivieren"} iconName="box-archive" onClick={() => onArchive(route.id)} />

                </DropDownButton>
            </div>
            <div className={`circle ${route.color}`} onClick={onClose}>
                <div className="circle-close">
                    <div className="close-icon">
                        <FontAwesomeIcon icon="caret-down"/>
                    </div>
                </div>
                {route.level}
            </div>
            <div className='table-wrapper'>

                <ul className="challenge-list">
                    {logs.map(log => (<li className="challenge-item">
                        <div className="info">
                            <div className="name">{log.person}</div>
                            <div className="date">Geschafft am: {log.date}</div>
                        </div>
                        <div
                            className={`status ${log.done ? 'completed' : 'not-completed'}`}>{log.done ? 'Geschafft' : 'Nicht geschafft'}</div>
                    </li>))}
                </ul>

                <div className="add-entry-form">
                    <div className="form-group">
                        <label for="name">Name:</label>
                        <select onChange={(e) => setNewPerson(e.target.value)}>
                            <option value={null}>-</option>
                            <option>Lukas</option>
                            <option>Simon</option>
                            <option>Christina</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label for="status">Status:</label>
                        <select onChange={(e) => setNewDone(e.target.value === 'completed')}>
                            <option value="completed">Geschafft</option>
                            <option value="not-completed">Nicht Geschafft</option>
                        </select>
                    </div>
                    <button className='add-log-button' onClick={onCreateLog} disabled={newPerson == null}>Loggen
                    </button>
                </div>
            </div>
        </div>

    );
}

export default SelectedRouteWidget;