import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faCaretLeft,
    faCaretRight,
    faCaretDown,
    faCaretUp,
    faCheck,
    faCheckSquare,
    faCoffee,
    faXmark,
    faTrash,
    faBoxArchive
} from '@fortawesome/free-solid-svg-icons'

library.add(faCheckSquare, faCoffee, faCaretUp, faCaretDown, faCheck, faXmark, faTrash, faCaretLeft, faCaretRight, faBoxArchive);

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <QueryClientProvider client={queryClient}>
    <App />
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
