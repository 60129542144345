import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './DropDownMenuButton.css';

function DropDownMenuButton({text, iconName, onClick}) {
    return (<li className="drop-down-content-button" onClick={onClick}>
        <span className="drop-down-button-icon">{ iconName && <FontAwesomeIcon icon={iconName} />}</span>
        <span className="drop-down-button-text">{text}</span>
    </li>)
}

export default DropDownMenuButton;